'use strict'
const doesHaveAll = (props, obj) => props.every(propName => !!obj[propName])
const createCssResolver = (mandatoryProps, resolver) => (styleData, overrideNestedInlineStyles) => doesHaveAll(mandatoryProps, styleData) ? resolver(styleData, overrideNestedInlineStyles) : ''
function buildCssRule(propName, value, withImportant) {
    value = withImportant ? `${value} !important` : value
    return `${propName}:${value};`
}

const fontFamilyResolver = createCssResolver(['fontFamily'], ({fontFamily}, withImportant) => buildCssRule('font-family', fontFamily, withImportant))
const fontWeightResolver = createCssResolver(['fontWeight'], ({fontWeight}, withImportant) => buildCssRule('font-weight', fontWeight, withImportant))
const fontStyleResolver = createCssResolver(['fontStyle'], ({fontStyle}, withImportant) => buildCssRule('font-style', fontStyle, withImportant))
const textDecorationResolver = createCssResolver(['textDecoration'], ({textDecoration}, withImportant) => buildCssRule('text-decoration', textDecoration, withImportant))
const textAlignResolver = createCssResolver(['textAlign'], ({textAlign}, withImportant) => buildCssRule('text-align', textAlign, withImportant))
const letterSpacingResolver = createCssResolver(['letterSpacing'], ({letterSpacing}, withImportant) => buildCssRule('letter-spacing', letterSpacing, withImportant))
const lineHeightResolver = createCssResolver(['lineHeight'], ({lineHeight}, withImportant) => buildCssRule('line-height', lineHeight, withImportant))
const getColorResolver = themeData => createCssResolver(['color'], ({color}, withImportant) => {
    const colorValue = /color_/.test(color) ? themeData.color[color.split('color_')[1]] : color
    return buildCssRule('color', colorValue, withImportant)
})
const getBackgroundColorResolver = themeData => createCssResolver(['backgroundColor'], ({backgroundColor}, withImportant) => {
    const backgroundColorValue = /color_/.test(backgroundColor) ? themeData.color[backgroundColor.split('color_')[1]] : backgroundColor
    return buildCssRule('background-color', backgroundColorValue, withImportant)
})

const simpleFontSizeResolver = createCssResolver(['fontSize'], ({fontSize}) => buildCssRule('font-size', fontSize))

const FALLBACK_MINIMUM_VIEWPORT = 320
const FALLBACK_MAXIMUM_VIEWPORT = 1920

const textScaleResolver = (minViewportSize = FALLBACK_MINIMUM_VIEWPORT, maxViewportSize = FALLBACK_MAXIMUM_VIEWPORT) =>
    createCssResolver(
        ['textScale__minFontSize', 'textScale__maxFontSize'],
        ({textScale__minFontSize, textScale__maxFontSize}) => {
            const fontSizeValue = `calc(${textScale__minFontSize}px + (${textScale__maxFontSize} - ${textScale__minFontSize}) * ((100vw - ${minViewportSize} * 1px) / (${maxViewportSize} - ${minViewportSize})))`
            return buildCssRule('font-size', fontSizeValue)
        }
    )

module.exports = (envMinViewport, envMaxViewport, themeData) => ({
    colorResolver: getColorResolver(themeData),
    backgroundColorResolve: getBackgroundColorResolver(themeData),
    fontFamilyResolver,
    fontWeightResolver,
    fontStyleResolver,
    textDecorationResolver,
    textAlignResolver,
    letterSpacingResolver,
    lineHeightResolver,
    fontSizeResolver: styleDataItem => textScaleResolver(envMinViewport, envMaxViewport)(styleDataItem) || simpleFontSizeResolver(styleDataItem)
})
